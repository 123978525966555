import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useFooterOffset } from '../Contexts/FooterOffsetContext';
import SubHeader from '../Components/SubHeader';
import { getCategories } from '../api';


function Categories() {

    const [categoryList, setCategoryList] = useState([]);
    const { setOffset } = useFooterOffset();

    const [tablet] = useState(window.innerWidth > 768 ? true : false)



    const getData = () => {
        getCategories()
            .then((response) => {
                setCategoryList(response.data)
            })
    }

    useEffect(() => {
        getData();

        document.title = 'Ürünler';

        setOffset(tablet > 768 ? 425 : 300);
        return () => {
            setOffset(0);
        }
    }, [])

    return (
        <>
            <SubHeader header='Ürünler' crumbs={[{ name: 'Anasayfa', path: '/' }, { name: 'Ürünler', path: '/categories' }]} />

            <MDBContainer fluid className='bg-white'>
                <MDBRow className='justify-content-center'>
                    <MDBCol md='10' lg='8' xl='7' className='mb-5'>
                        <MDBRow className='pt-3'>
                            {categoryList.map((category, index) => {
                                return (
                                    <MDBCol md='6' lg='4' key={index} className='animate__animated animate__backInRight'>
                                        <div className='p-4 rounded bg-image hover-overlay hover-zoom hover-shadow'>
                                            <div className='d-flex align-items-center justify-content-center'>
                                                <img
                                                    src={category.resim}
                                                    className='img-fluid shadow-2-strong'
                                                    alt='...'
                                                    style={{height: '30vh', objectFit: 'cover'}}
                                                />
                                                <Link to={`/category/${category.slug}-c-${category.id}`}>
                                                    <div className='mask' style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}></div>
                                                </Link>
                                                </div>
                                                <div className='text-center mt-4' style={{
                                                    height: '9vh',
                                                    overflow: 'hidden'

                                                }}>
                                                <h5 className='fw-bold'>{category.isim}</h5>
                                                <p className='text-muted mb-0'>{category.aciklama}</p>
                                            </div>
                                        </div>
                                    </MDBCol>
                                )
                            })}
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
            
        </>
    );
}

export default Categories;
