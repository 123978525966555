import { Outlet, useNavigate } from "react-router-dom";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
import background from './assets/images/bgImage.jpeg'
import { FloatingWhatsApp } from "react-floating-whatsapp";
import { Fab } from "@mui/material";
import { FooterOffsetProvider } from "./Contexts/FooterOffsetContext";


function App() {

  const navigate = useNavigate();

  return (
    <FooterOffsetProvider>
      <main>
        <Header />      

        <FloatingWhatsApp
          phoneNumber="+48573560997"
          accountName="Fiba Metal"
          avatar={background}
          chatMessage="Merhaba, nasıl yardımcı olabilirim?"
          placeholder="Mesajınızı yazın"
          statusMessage="Çevrimiçi"
          allowEsc
          allowClickAway
          onClick={() => window.open('https://wa.me/+48573560997')}
          notification={true}
          notificationSound={true}
          notificationDelay={10}
          notificationLoop={false}
        />

        <div style={{position: 'fixed', bottom: '110px', right: '25px', zIndex: '1'}} className="animate__animated animate__fadeInRightBig fab-right"  onClick={() => navigate('/prices')}>
          <Fab variant="extended" color="primary" className="d-flex flex-row justify-content-center align-items-center">
            <div className='me-2 h3 fw-semibold mt-2'>FİYATLAR</div>
            <i class="fa-solid fa-list-check fa-2xl"></i>
          </Fab>
        </div>
        
        <div onClick={() => window.location.href = 'tel:+48573560997'} className="animate__animated animate__fadeInLeftBig fab-left" style={{position: 'fixed', bottom: '110px', left: '25px', zIndex: '1'}}>
          <Fab variant="extended" color="primary">
            <i class="fa-solid fa-phone-volume fa-2xl"></i>
            <div className='ms-2 h3 fw-semibold mt-2'>ARA</div>
          </Fab>
        </div>

        
        <Outlet />

        

        <Footer />
      </main>
    </FooterOffsetProvider>
  );
}

export default App;
