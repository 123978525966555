import React, { useState } from 'react'
import SubHeader from '../Components/SubHeader'
import { MDBCol, MDBContainer, MDBIcon, MDBRow } from 'mdb-react-ui-kit'
import GoogleMapComponent from '../Components/GoogleMapComponent'
import ReCAPTCHA from 'react-google-recaptcha'
import { useEffect } from 'react'
import { useFooterOffset } from '../Contexts/FooterOffsetContext'
import { sendMessage } from '../api'

function Contact() {

    const { setOffset } = useFooterOffset();

    const [captcha, setCaptcha] = useState(false);

    const [form, setForm] = useState({
        isim: '',
        soyisim: '',
        email: '',
        telefon: '',
        mesaj: '',
    });


    useEffect(() => {
        setOffset(250);

        document.title = 'İletişim';

        return () => {
            setOffset(0);
        }
    }, [setOffset])


    const submitHandler = (e) => {

        e.preventDefault();

        if (captcha) {
            sendMessage(form)
                .then((response) => {
                    if (response.status === 201) {
                        alert('Mesajınız başarıyla gönderildi.');
                        setForm({
                            isim: '',
                            soyisim: '',
                            email: '',
                            telefon: '',
                            mesaj: '',
                        });
                    }
                })
                .catch((error) => {
                    alert('Mesajınız gönderilirken bir hata oluştu.');
                })
        } else {
            alert('Lütfen güvenlik doğrulamasını tamamlayınız.');
        }
        
    }

    return (
        <>
            <SubHeader header='İletişim' crumbs={[{ name: 'Anasayfa', path: '/' }, { name: 'İletişim', path: '/contact' }]} />

            <section className='bg-white'>

                <MDBContainer className='text-center text-md-start p-5'>

                    <MDBRow className='justify-content-center g-5 animate__animated animate__zoomIn'>
                        <MDBCol md='6' lg='6' xl='5' className='mb-5'>
                            <h1 className='fw-semibold'>İletişim Bilgilerimiz</h1>

                            <div className='border-bottom border-warning w-25 border-2 pb-2' />
                            
                            <div className='mt-5'>
                                <GoogleMapComponent />
                            </div>

                            <div className='mt-5'>
                                <p>
                                    <MDBIcon color='secondary' icon='home' className='me-2' />
                                    Rami kışla caddesi topçular mahallesi <br />
                                    58-60 G YÖNTEM VAYTAŞ PLAZA <br />
                                    İSTANBUL / Eyüpsultan
                                </p>
                                <p onClick={() => window.location.href = 'mailto:info@fibametal.com'} style={{cursor: 'pointer'}}>
                                    <MDBIcon color='secondary' icon='envelope' className='me-3' />
                                    info@fibametal.com
                                </p>
                                <p onClick={() => window.location.href = 'tel:+48573560997'} style={{cursor: 'pointer'}}>
                                    <MDBIcon color='secondary' icon='phone' className='me-3' /> +4 857 356 0997
                                </p>
                            </div>
                        </MDBCol>

                        <MDBCol md='6' lg='6' xl='7' className='mb-5'>
                            <h1 className='fw-semibold'>Mesaj</h1>

                            <div className='border-bottom border-warning w-25 border-2 pb-2' />

                            <div className='mt-5'>
                                <form onSubmit={submitHandler}>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className='form-group mb-3'>
                                                <label htmlFor='name' className='form-label'>Ad</label>
                                                <input type='text' className='form-control' id='name' placeholder='Adınız' value={form.isim} required onChange={(e) => {
                                                    setForm({ ...form, isim: e.target.value })
                                                }} />
                                            </div>
                                        </div>

                                        <div className='col-md-6'>
                                            <div className='form-group mb-3'>
                                                <label htmlFor='surname' className='form-label'>Soyad</label>
                                                <input type='text' className='form-control' id='surname' placeholder='Soyadınız' value={form.soyisim} required onChange={(e) => {
                                                    setForm({ ...form, soyisim: e.target.value })
                                                }} />
                                            </div>
                                        </div>
                                    </div>

                                    <MDBRow className='align-items-center'>
                                        <div className='col-md-6'>
                                            <div className='form-group mb-3'>
                                                <label htmlFor='email' className='form-label'>E-Posta</label>
                                                <input type='email' className='form-control' id='email' placeholder='E-Posta Adresiniz' value={form.email} required onChange={(e) => {
                                                    setForm({ ...form, email: e.target.value })
                                                }} />
                                            </div>
                                        </div>

                                        <div className='col-md-6'>
                                            <div className='form-group mb-3'>
                                                <label htmlFor='phone' className='form-label'>Telefon</label>
                                                <input type='text' className='form-control' id='phone' placeholder='Telefon Numaranız' value={form.telefon} required onChange={(e) => {
                                                    setForm({ ...form, telefon: e.target.value })
                                                }} />
                                            </div>
                                        </div>
                                    </MDBRow>

                                    <div className='form-group mb-3'>
                                        <label htmlFor='message' className='form-label'>Mesaj</label>
                                        <textarea className='form-control' id='message' rows='3' placeholder='Mesajınız' value={form.mesaj} required onChange={(e) => {
                                            setForm({ ...form, mesaj: e.target.value })
                                        }}/>
                                    </div>


                                    <div className='form-group mb-3 d-flex justify-content-center justify-content-lg-start'>
                                        <ReCAPTCHA
                                            sitekey='6LfhgGYoAAAAAHmK-cKvXFcOJVNgmiLS8I0VPxPP'
                                            onChange={() => setCaptcha(true)}
                                        />
                                    </div>

                                        

                                    <div className='form-group mb-3'>
                                        <button className='btn btn-warning btn-lg' type='submit'>Gönder</button>
                                    </div>
                                </form>
                            </div>


                        </MDBCol>

                    </MDBRow>

                </MDBContainer>


            </section>
        </>
    )
}

export default Contact
