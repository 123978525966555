import React, { createContext, useContext, useState } from 'react';

const FooterOffsetContext = createContext();

export const useFooterOffset = () => {
    return useContext(FooterOffsetContext);
}

export const FooterOffsetProvider = ({ children }) => {
    const [offset, setOffset] = useState(0);  // varsayılan değer

    return (
        <FooterOffsetContext.Provider value={{ offset, setOffset }}>
            {children}
        </FooterOffsetContext.Provider>
    );
}
