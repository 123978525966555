import { MDBCard, MDBCardBody, MDBCardImage, MDBCardText, MDBCardTitle, MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import React, { useEffect, useState } from 'react'
import 'animate.css'
import { Swiper, SwiperSlide } from 'swiper/react';

import { Pagination, Navigation } from 'swiper/modules';
import GoogleMapComponent from '../Components/GoogleMapComponent';
import { Link } from 'react-router-dom';
import { useFooterOffset } from '../Contexts/FooterOffsetContext';
import { getCategories } from '../api'

import Carousel from '../Components/Carousel'

function Home() {

    const [categoryList, setCategoryList] = useState([]);

    const [tablet] = useState(window.innerWidth > 768 ? true : false)

    const { setOffset } = useFooterOffset();

    const [showRow, setShowRow] = useState(tablet ? false : true);
    const [showRow2, setShowRow2] = useState(false);


    useEffect(() => {
        getData();
        
        const checkScrollPosition = () => {
            // Örnek olarak 400px'den sonra göstermek istediğimizi varsayalım
            if (window.scrollY > 400) {
                setShowRow(true);
            }

            if (window.scrollY > 600) {
                setShowRow2(true);
            }
        };

        window.addEventListener('scroll', checkScrollPosition);

        document.title = 'Fiba Metal';

        setOffset(tablet > 768 ? 2450 : 2000);

        // Component unmount olduğunda listener'ı kaldır
        return () => {window.removeEventListener('scroll', checkScrollPosition); setOffset(0)}
    }, []);

    const getData = () => {
        getCategories()
            .then((response) => {
                setCategoryList(response.data)
            })
    }

    return (
        <>
            <Carousel />

            { tablet &&
                <MDBContainer fluid>
                    <MDBRow style={{backgroundColor: '#53b147'}}>
                        <MDBCol size={6} className='d-none d-sm-flex justify-content-center align-items-center text-light'>
                            <MDBRow>
                                <MDBCol offsetLg={10} size={12}>
                                    <div className='h1 fs-2 fw-bold banner user-select-none'>FİBA METAL</div>
                                </MDBCol>
                                <MDBCol offsetLg={10} size={12}>
                                    <div className='fs-6 fw-semibold banner user-select-none'>Bizi Arayın</div>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol className='d-flex col col-lg-4 justify-content-center align-items-center text-light p-3 w-25'>
                            <MDBCol
                                className='shadow d-flex flex-row align-items-center justify-content-between justify-content-lg-center ms-lg-5 text-center p-3 square border border-3 border-white rounded-2 h2 fw-semibold user-select-none'
                                onClick={() => window.location.href = 'tel:+48573560997'}
                            >
                                <div className='me-lg-3 banner'><i className="fa-solid fa-phone"></i></div>
                                <div className='banner'>+4 857 356 0997</div>
                            </MDBCol>
                            
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            }

            <MDBContainer fluid>
                {tablet &&
                    <div className='b-example-divider'></div>
                }
                {showRow && 
                <MDBRow>
                    <MDBCol className='text-center justify-content-center d-flex bg-white'>
                        <MDBCardBody className='align-self-center animate__animated animate__fadeInLeftBig'>
                            <MDBCardTitle className='mt-5 ms-4 text-start text-md-center'>
                                <div className='display-5 fw-bold'>FİBA METAL</div>
                                <div className='h3 fw-semibold'>GERİ DÖNÜŞÜM</div>
                            </MDBCardTitle>
                            <MDBCardText className='my-5 h5 text-start text-md-center d-flex justify-content-center'>
                                <MDBCol size={10} lg={3}>
                                    <strong>FİBA METAL</strong>, 1986 yılında <strong>İstanbul EYÜP TOPÇULAR</strong>‘DA bakır, sarı, çinko, bronz, kurşun, kalay, nikel ve alüminyum hurdalarının ticaretini yapmak üzere kurulmuş olup, günümüzde ise hurda kablo, hurda krom ve demir dahil olmak üzere tüm metal çeşitlerine hitap etmektedir.
                                </MDBCol>
                            </MDBCardText>
                        </MDBCardBody>
                    </MDBCol>
                </MDBRow>
                }

                {tablet &&
                    <div className='b-example-divider'></div>
                }

                {showRow2 &&
                <>
                
                    <MDBRow className={`bg-white justify-content-center pt-5 pb-lg-5 ${!tablet && 'border-top border-2'}`}>
                        <MDBCol className='text-center col-12 bg-white px-5 animate__animated animate__bounceInLeft '>
                            <div className='display-6 fw-semibold mt-lg-5 px-lg-5 mx-lg-5 my-lg-2'>ÜRÜNLERİMİZ</div>
                        </MDBCol>

                        <MDBCol size={8} className='animate__animated animate__bounceInLeft'>
                            <Swiper
                                // slidesPerView={3}
                                // spaceBetween={50}
                                loop={true}
                                // pagination={{clickable: true}}
                                navigation={false}
                                modules={[Pagination, Navigation]}
                                breakpoints={{
                                    640: {
                                        slidesPerView: 2,
                                        spaceBetween: 20,
                                    },
                                    768: {
                                        slidesPerView: 3,
                                        spaceBetween: 30,
                                    },
                                    1024: {
                                        slidesPerView: 4,
                                        spaceBetween: 30,
                                    },
                                }}
                            >
                                {categoryList.map((category) => (
                                    <SwiperSlide key={category.id}>
                                        <MDBCol className='my-5'>
                                            <MDBCard shadow='0' className='bg-image hover-overlay hover-zoom hover-shadow'>
                                                <div>
                                                <MDBCardImage
                                                    src={category.resim}
                                                    alt='...'
                                                    position='top'
                                                    className='hover'
                                                    style={{height: '30vh', objectFit: 'cover'}}
                                                />
                                                <Link to={`/category/${category.slug}-c-${category.id}`}>
                                                    <div className='mask' style={{ backgroundColor: 'rgba(57, 192, 237, 0.2)' }}></div>
                                                </Link>
                                                </div>
                                                <MDBCardBody className='user-select-none mb-4'  style={{
                                                    height: '20vh',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                }}>
                                                    <MDBCardTitle className='h4 fw-bold'>{category.isim}</MDBCardTitle>
                                                    <MDBCardText className='fs-6'>
                                                        {category.aciklama}
                                                    </MDBCardText>
                                                </MDBCardBody>
                                            </MDBCard>
                                        </MDBCol>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </MDBCol>
                        
                    </MDBRow>
                </>
                }
                
            </MDBContainer>

            <GoogleMapComponent />
        </>
    )
}

export default Home
