import React from 'react'
import { GoogleMap, useJsApiLoader, Marker, InfoWindow } from '@react-google-maps/api';
import { MDBCard, MDBCardBody, MDBCardSubTitle, MDBCardText, MDBCardTitle } from 'mdb-react-ui-kit';

const containerStyle = {
    width: '100%',
    height: '50vh'
};

const center = {
    lat: 41.041279,
    lng: 28.920820
};

function GoogleMapComponent() {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyC_mu2RTFLmnQdoE-MHBi6SEYg7F0hYKD0"
    })

    const [map, setMap] = React.useState(null)

    const onLoad = React.useCallback(function callback(map) {
        // This is just an example of getting and using the map instance!!! don't just blindly copy!
        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={15}
            onLoad={onLoad}
            onUnmount={onUnmount}
        >
            <InfoWindow position={new window.google.maps.LatLng(center)}>
                <MDBCard>
                    {/* <MDBCardImage src={image} alt='...' position='top' style={{width: '175px', height: '150px', alignSelf: 'center'}} /> */}
                    <MDBCardBody>
                        <MDBCardTitle>Fiba Metal</MDBCardTitle>
                        <MDBCardSubTitle>Rami kışla caddesi topçular mahallesi <br /> 58-60 G YÖNTEM VAYTAŞ PLAZA 
                        <br />İSTANBUL / Eyüpsultan</MDBCardSubTitle>
                        <MDBCardText className='mt-4'>
                            <div>
                                <span className='fw-semibold'>AÇIK</span> <span className='float-end'>8:00 - 22:00</span><br />
                                <span className='fw-semibold'>TELEFON</span> <span className='float-end' onClick={() => window.location.href = 'tel:+48573560997'} style={{cursor: 'pointer'}}>+4 857 356 0997</span><br />
                                <span className='fw-semibold'>MAIL</span> <span className='float-end' onClick={() => window.location.href = 'mailto:info@fibametal.com'} style={{cursor: 'pointer'}}>info@fibametal.com</span>
                            </div>
                        </MDBCardText>
                    </MDBCardBody>
                </MDBCard>
            </InfoWindow>
            <Marker
                position={center}
                title="İstanbul, Türkiye"
                animation={window.google.maps.Animation.DROP}
            />
        </GoogleMap>
    ) : <></>
}

export default React.memo(GoogleMapComponent)
