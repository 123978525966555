import React, { useState } from 'react'
import SubHeader from '../Components/SubHeader'
import { MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, styled } from '@mui/material';
import { getCategories, getProducts } from '../api';
import { useFooterOffset } from '../Contexts/FooterOffsetContext';


const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
    border: 0,
    },
}));


function Prices() {

    const [categories, setCategories] = React.useState([]);
    const [products, setProducts] = React.useState({});

    const [tablet] = useState(window.innerWidth > 768 ? true : false)


    const { setOffset } = useFooterOffset();

    const today = new Date().toLocaleDateString('tr-TR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    }).replace(/[^0-9]/g, '.');


    const getCategoryData = () => {
        getCategories()
            .then((response) => {
                setCategories(response.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    React.useEffect(() => {
        getCategoryData();

        setOffset(tablet > 768 ? 425 : 325);


        document.title = 'Fiyatlar';

        return () => {
            setOffset(0);
        }
    }, [])

    React.useEffect(() => {
        let products = {};

        const fetchProductsForCategory = async (category) => {
            const response = await getProducts({ kategori: category.id});
            products[category.id] = response.data;
        }

        Promise.all(categories.map((category) => fetchProductsForCategory(category)))
            .then(() => setProducts(products))
            .catch((error) => console.log(error))
    }, [categories])

    return (
        <>
            <SubHeader header={'Fiyatlar'} crumbs={[{ name: 'Anasayfa', path: '/' }, { name: 'Fiyatlar', path: '/contact' }]} />

            <section className='bg-white'>
                <MDBContainer className='text-center text-md-start pt-5 p-md-5 pb-5'>
                    <h3 className='fw-semibold text-center'>Fiba Metal {today} Tarihli Hurda Alım Fiyatlarıdır</h3>

                    <MDBRow className='justify-content-center g-4 g-md-5 animate__animated animate__zoomIn mt-2'>
                        {/* <Paper sx={{ width: '50%', overflow: 'hidden' }}> */}
                        {[...categories].sort((a, b) => products[b.id]?.length - products[a.id]?.length).map((category, index) => {

                            let categoryProducts = products[category.id] || []
                            
                            return (
                                <div className='col-md-6' key={index} >
                                    <TableContainer className='shadow rounded-3'>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="center" className='bg-warning text-dark fs-5 fw-semibold' colSpan={12}>{category.isim} Alım Fiyatlarımız</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {categoryProducts.length > 0 ? 
                                                    categoryProducts.map((product, index) => {
                                                        return (
                                                            <StyledTableRow key={index}>
                                                                <TableCell align="center" className='fw-semibold'>{product.isim}</TableCell>
                                                                <TableCell align="center">{product.fiyat} ₺/kg</TableCell>
                                                            </StyledTableRow>
                                                        )
                                                    })
                                                    :
                                                    <TableRow>
                                                        <TableCell align="center" colSpan={12} className='fw-semibold'>Ürün bulunamadı</TableCell>
                                                    </TableRow>
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </div>
                            )
                        })}
                        
                        
                        {/* </Paper> */}
                    </MDBRow>
                </MDBContainer>
            </section>
        </>
        
    )
}

export default Prices
