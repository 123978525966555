import React from 'react'
import { MDBBreadcrumb, MDBBreadcrumbItem, MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import { Link } from 'react-router-dom'


function SubHeader({ header, crumbs }) {
  return (
    <>
        <header className='text-center text-lg-start text-dark mt-4 mt-md-0 pt-5'>
            <section>
                    <div className='container w-75 mt-md-5 pt-3'>
                        <MDBCol className='m-md-5 mb-0 p-5 pb-4 text-white align-self-center animate__animated animate__fadeInLeftBig user-select-none'>
                            <h1 className='display-4 fw-bold banner'>{header}</h1>
                        </MDBCol>
                    </div>
            </section>

            <section className='bg-light border-bottom'>
                    <MDBContainer className='text-center text-md-start shadow-lg'>
                        <MDBRow className='mt-3'>
                        <>
                                <MDBCol sm={12} className='mx-auto align-self-center animate__animated animate__fadeInDown'>
                                    <div className='mt-4 text-center'>
                                        <section className='mb-4 ms-5'>
                                            <MDBBreadcrumb bold color='warning'>
                                                {crumbs.map((crumb, index) => {

                                                    if (index === crumbs.length - 1) {
                                                        return (
                                                            <MDBBreadcrumbItem key={index} active aria-current='page' className='user-select-none'>
                                                                {crumb.name}
                                                            </MDBBreadcrumbItem>
                                                        )
                                                    } else
                                                    return (
                                                        <MDBBreadcrumbItem key={index}>
                                                            <Link to={crumb.path} className='text-reset'>
                                                                {crumb.name}
                                                            </Link>
                                                        </MDBBreadcrumbItem>
                                                    )
                                                })}
                                            </MDBBreadcrumb>
                                        </section>
                                    </div>
                                </MDBCol>
                            </>
                        </MDBRow>
                    </MDBContainer>
                </section>
        </header>
    </>
  )
}

export default SubHeader
