import axios from "axios";



const api = axios.create({
    // baseURL: "http://localhost:8000/",
    baseURL: "https://fibametal.pythonanywhere.com/",
    headers: {
        "Content-Type": "application/json",
    },
});



export const getCategories = async () => {
    const response = await api.get("api/categories/");
    return response;
}


export const getProducts = async (filters) => {
    const response = await api.get("api/products/", { params: filters });
    return response;
}


export const sendMessage = async (data) => {
    const response = await api.post("api/create_message/", data);
    return response;
}


export const getCategoryDetail = async (id) => {
    const response = await api.get(`api/categories/${id}/`);
    return response;
}


export const getProductDetail = async (id) => {
    const response = await api.get(`api/products/${id}/`);
    return response;
}
