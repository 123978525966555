import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useFooterOffset } from '../Contexts/FooterOffsetContext';
import SubHeader from '../Components/SubHeader';
import { getCategoryDetail, getProducts } from '../api';


function Category() {

    const { params } = useParams();
    const id = params.split('-c-')[1];

    const [productList, setProductList] = useState([]);
    const { setOffset } = useFooterOffset();

    const [categoryDetail, setCategoryDetail] = useState({});


    useEffect(() => {

        getProducts({ kategori: id })
        .then((response) => {
            setProductList(response.data);
        })

        getCategoryDetail(id)
        .then((response) => {
            setCategoryDetail(response.data);
        })
    }, [id])

    useEffect(() => {
        document.title = `${categoryDetail.isim} | Fiba Metal`;
    }, [categoryDetail])

    useEffect(() => {

        if (productList.length === 0) {
            setOffset(50);
        } else if (productList.length > 2){
            setOffset(Math.floor(productList.length / 3) * 375);
        } else {
            setOffset(375);
        }

        return () => {
            setOffset(0);
        }

    }, [productList])

    return (
        productList.length > 0 ?
        <>
            <SubHeader header='Ürünler' crumbs={[{ name: 'Anasayfa', path: '/' }, { name: 'Ürünler', path: '/categories' }, { name: categoryDetail.isim, path: `/category/${params}`}]} />

            <section className='bg-white mb-5'>
                <MDBContainer fluid>
                    <MDBRow className='justify-content-center'>
                        <MDBCol md='10' lg='8' xl='7' className='mb-5'>
                            <MDBRow className='pt-3'>
                                {productList.map((product, index) => {
                                    return (
                                        <MDBCol md='6' lg='4' key={index} className='animate__animated animate__backInRight'>
                                            <div className='p-4 rounded bg-image hover-overlay hover-zoom hover-shadow'>
                                                <div className='d-flex align-items-center justify-content-center'>
                                                    <img
                                                        src={product.resim}
                                                        className='shadow-2-strong'
                                                        alt='...'
                                                        height={290}
                                                        width={290}
                                                    />
                                                    <Link to={`/category/${params}/product/${product.slug}-p-${product.id}`}>
                                                        <div className='mask' style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}></div>
                                                    </Link>
                                                    </div>
                                                    <div className='text-center mt-4' style={{
                                                        height: '9vh',
                                                        overflow: 'hidden'

                                                    }}>
                                                    <h5 className='fw-bold'>{product.isim}</h5>
                                                    <p className='text-muted mb-0'>{product.aciklama}</p>
                                                </div>
                                            </div>
                                        </MDBCol>
                                    )
                                })}
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>

                <MDBContainer>
                    <MDBRow>
                        <h3 className='fw-bold'>{categoryDetail.isim}</h3>

                        <div className='border-bottom border-warning border-2 pb-2 my-3' style={{width: '10%'}} />

                        <div className='mt-3'>
                            <p className='text-muted'>{categoryDetail.aciklama}</p>
                        </div>    
                    </MDBRow>
                </MDBContainer>
            </section>
            
        </>
        :
        <div className='d-flex justify-content-center align-items-center' style={{height: '100vh'}}>
            <div className="spinner-grow text-warning" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    );
}

export default Category
