import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
} from "react-router-dom";
import App from './App';
import Home from './Screens/Home';

import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import Categories from './Screens/Categories';
import Contact from './Screens/Contact';
import routes from './Components/routes.json'
import Prices from './Screens/Prices';
import Category from './Screens/Category';
import Product from './Screens/Product';



const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path={routes.home.path} element={<App />}>
      <Route path={routes.home.path} element={<Home />} />
      <Route path={routes.prices.path} element={<Prices />} />
      <Route path={routes.contact.path} element={<Contact />} />
      <Route path={routes.categories.path} element={<Categories />} />
      <Route path={routes.category.path} element={<Category />} />
      <Route path={routes.product.path} element={<Product />} />

      <Route path='/*' element={<Navigate to={routes.home.path} />} />
    </Route>
  )
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
