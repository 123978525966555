import React, { useEffect, useState } from 'react';
import {
  MDBNavbar,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBNavbarToggler,
  MDBContainer,
  MDBIcon,
  MDBCollapse,
  MDBNavbarBrand,
  MDBCol,
  MDBRow,
  MDBBtn
} from 'mdb-react-ui-kit';
import { useNavigate } from 'react-router-dom';
import routes from './routes';
import logo from  '../assets/images/logo.png'


function Header() {
  const [showBasic, setShowBasic] = useState(window.innerWidth > 768);
  const navigate = useNavigate();
  const [scrollPos, setScrollPos] = useState(0);

  const [tablet] = useState(window.innerWidth > 768 ? true : false)
  const logoHeight = tablet ? '75' : window.innerWidth > 480 ? '60' : '50';


  const navbarStyle = {
    backgroundColor: !tablet ? '#333' : scrollPos > 200 || !tablet ? '#333' : 'rgba(0, 0, 0, 0.6)', 
    height: showBasic && !tablet ? '35vh' : '12vh',
    top: scrollPos > 200 || !tablet ? '0' : '9vh',
  };

  useEffect(() => {
    const checkScrollPosition = () => {
      setScrollPos(window.scrollY);
    };

    window.addEventListener('scroll', checkScrollPosition);

    return () => window.removeEventListener('scroll', checkScrollPosition);
  }, [])

  return (
    <>
      <header>
        { tablet &&
        <MDBNavbar expand='sm' className='d-none d-md-flex fixed-top shadow-none' style={{height: '9vh', backgroundColor: 'rgba(0, 0, 0, 0.6)'}}>
          <MDBContainer fluid style={{height: '5vh'}} className='p-5'>
            <MDBRow className='text-white w-100 justify-content-center align-items-center'>
              <MDBCol size={6} className='d-flex justify-content-between align-items-end border-bottom pb-3'>
                {tablet  && (
                  <>
                    <MDBCol size={6} className='d-flex justify-content-between'>
                      <div onClick={() => window.location.href = 'tel:+48573560997'} style={{cursor: 'pointer'}}>
                        <MDBIcon icon='phone-alt' className='me-2' />
                        <span className='fw-bold'>+4 857 356 0997</span>
                      </div>
                      <div>
                        <MDBIcon icon='envelope' className='me-2 ms-4' />
                        <span className='fw-bold'>
                          <a href='mailto:info@fibametal.com' className='text-white'>
                            info@fibametal.com
                          </a>
                        </span>
                      </div>
                    </MDBCol>
                
                  <MDBCol size={6} className='text-end'>
                    <MDBBtn outline color="light" floating className='m-1' href='/' role='button'>
                        <MDBIcon fab icon='facebook-f' />
                    </MDBBtn>
                    <MDBBtn outline color="light" floating className='m-1' href='/' role='button'>
                        <MDBIcon fab icon='twitter' />
                    </MDBBtn>
                    <MDBBtn outline color="light" floating className='m-1' href='/' role='button'>
                        <MDBIcon fab icon='google' />
                    </MDBBtn>
                    <MDBBtn outline color="light" floating className='m-1' href='https://www.instagram.com/fibametall/' role='button'>
                      <MDBIcon fab icon='instagram' />  
                    </MDBBtn>
                  </MDBCol>
                  </>
                  )}
                </MDBCol>
              </MDBRow>
          </MDBContainer>
        </MDBNavbar>
        }
        
        <MDBNavbar expand='lg' dark className='fixed-top' style={navbarStyle}>
          <MDBContainer fluid className='justify-content-between'>
            <MDBCol lg={3} />

            <MDBCol md={9} lg={3} className='d-flex align-items-center justify-content-start'>
              <MDBNavbarBrand href='/'>
                <img
                  src={logo}
                  height={logoHeight}
                  alt=''
                  loading='lazy'
                />
              </MDBNavbarBrand>
              <MDBNavbarToggler
                color=''
                type='button'
                aria-expanded='false'
                aria-label='Toggle navigation'
                onClick={() => setShowBasic(!showBasic)}
              >
                <MDBIcon icon='bars' fas />
              </MDBNavbarToggler>
            </MDBCol>

            <MDBCol md={12} lg={6} className='ps-md-5 ms-md-4'>
              <MDBCollapse navbar show={showBasic}>
                <MDBNavbarNav fullWidth={false} className='mb-2 mb-lg-0 fw-semibold'>
                  <MDBNavbarItem>
                    <MDBNavbarLink aria-current='page' className='link-warning text-white' onClick={() => navigate(routes.home.path)}>
                      ANA SAYFA
                    </MDBNavbarLink>
                  </MDBNavbarItem>
                  <MDBNavbarItem>
                    <MDBNavbarLink aria-current='page' className='link-warning text-white' onClick={() => navigate(routes.categories.path)}>
                      ÜRÜNLER
                    </MDBNavbarLink>
                  </MDBNavbarItem>
                  <MDBNavbarItem>
                    <MDBNavbarLink className='link-warning text-white'  onClick={() => navigate(routes.prices.path)}>FİYATLAR</MDBNavbarLink>
                  </MDBNavbarItem>
                  <MDBNavbarItem>
                    <MDBNavbarLink className='link-warning text-white'  onClick={() => navigate(routes.contact.path)}>İLETİŞİM</MDBNavbarLink>
                  </MDBNavbarItem>
                </MDBNavbarNav>
              </MDBCollapse>
            </MDBCol>
          </MDBContainer>
        </MDBNavbar>
      </header>
    </>
  );
}

export default Header;
