import React, { useEffect, useState } from 'react'
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon, MDBRipple, MDBBtn } from 'mdb-react-ui-kit';
import { Link, useLocation } from 'react-router-dom';
import routes from '../Components/routes';
import { useFooterOffset } from '../Contexts/FooterOffsetContext';
import { getCategories } from '../api';
import logo from  '../assets/images/logo.png'


function Footer() {

    const location = useLocation();
    const [categoryList, setCategoryList] = useState([]);
    const [showRow, setShowRow] = useState(false);
    const [showRow2, setShowRow2] = useState(false);

    const { offset } = useFooterOffset();

    const getData = () => {
        getCategories()
            .then((response) => {
                setCategoryList(response.data)
            })
    }

    useEffect(() => {
        setShowRow(false);
        setShowRow2(false);
    }, [location.pathname])

    useEffect(() => {
        getData();


        const checkScrollPosition = () => {
            // Örnek olarak 400px'den sonra göstermek istediğimizi varsayalım
            if (window.scrollY > offset) {
                setShowRow(true);
            }

            if (window.scrollY > offset + 100) {
                setShowRow2(true);
            }

        };

        window.addEventListener('scroll', checkScrollPosition);

        // Component unmount olduğunda listener'ı kaldır
        return () => window.removeEventListener('scroll', checkScrollPosition);
    }, [])
     
    return (
        <MDBFooter bgColor='dark' className='text-center text-lg-start text-light'>

            <section>
                <MDBContainer className='text-center text-md-start pt-4'>
                    <MDBRow className='mt-3'>
                        {showRow &&
                        <>
                            <MDBCol md='3' lg='4' xl='3' className='mx-auto align-self-center animate__animated animate__fadeInLeft'>
                                <div className='d-flex align-items-center justify-content-center'>
                                    <MDBRipple rippleColor='light' className='bg-image hover-overlay shadow-1-strong rounded'>
                                        <img src={logo} alt='' className='img-fluid' />
                                        <a href='/'>
                                            <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)' }}></div>
                                        </a>
                                    </MDBRipple>
                                </div>
                                <div className='border border-1 shadow-lg my-4' />
                                <div className='mt-4 text-center'>
                                    <section className='mb-lg-4'>
                                        <MDBBtn outline color="light" floating className='m-1' href='/' role='button'>
                                            <MDBIcon fab icon='facebook-f' />
                                        </MDBBtn>

                                        <MDBBtn outline color="light" floating className='m-1' href='/' role='button'>
                                            <MDBIcon fab icon='twitter' />
                                        </MDBBtn>

                                        <MDBBtn outline color="light" floating className='m-1' href='/' role='button'>
                                            <MDBIcon fab icon='google' />
                                        </MDBBtn>
                                        <MDBBtn outline color="light" floating className='m-1' href='https://www.instagram.com/fibametall/' role='button'>
                                            <MDBIcon fab icon='instagram' />
                                        </MDBBtn>
                                    </section>
                                </div>
                            </MDBCol>
                            
                            <div className='w-100 d-flex justify-content-center d-md-none'>
                                <div className='border-bottom my-4 w-50 align-self-center' />
                            </div>

                            <MDBCol md='2' lg='2' xl='2' className='mx-auto mb-lg-4 animate__animated animate__fadeInRight'>
                            <h6 className='text-uppercase fw-bold mb-4'>Popüler ürünler</h6>
                            
                                {categoryList?.slice(0,4).map((category) => 
                                    <p key={category.id}>
                                        <Link to={`/categories/${category.slug}`} className='text-reset'>
                                            {category.isim}
                                        </Link>
                                    </p>
                                )}
                            </MDBCol>

                            <div className='w-100 d-flex justify-content-center d-md-none'>
                                <div className='border-bottom mb-4 w-50 align-self-center' />
                            </div>
                
                            <MDBCol md='3' lg='2' xl='2' className='mx-auto mb-lg-4 animate__animated animate__fadeInRight'>
                            <h6 className='text-uppercase fw-bold mb-4'>Hızlı Erişim</h6>
                            <p>
                                <Link to={routes.home.path} className='text-reset'>
                                    Ana Sayfa
                                </Link>
                            </p>
                            <p>
                                <Link to={routes.categories.path} className='text-reset'>
                                    Ürünler
                                </Link>
                            </p>
                            <p>
                                <Link to={routes.prices.path} className='text-reset'>
                                    Fiyat Listesi
                                </Link>
                            </p>
                            <p>
                                <Link to={routes.contact.path} className='text-reset'>
                                    İletişim
                                </Link>
                            </p>
                            </MDBCol>

                            <div className='w-100 d-flex justify-content-center d-md-none'>
                                <div className='border-bottom mb-4 w-50 align-self-center' />
                            </div>
                
                            <MDBCol md='4' lg='3' xl='3' className='mx-auto mb-md-0 mb-4 animate__animated animate__fadeInRight'>
                                <h6 className='text-uppercase fw-bold mb-4'>İletişim</h6>
                                <p>
                                    <MDBIcon color='secondary' icon='home' className='me-2' />
                                    Rami kışla caddesi topçular mahallesi 58-60 G 
                                    YÖNTEM VAYTAŞ PLAZA 
                                    İSTANBUL / Eyüpsultan
                                </p>
                                <p>
                                    <a href='mailto:info@fibametal.com' className='text-white'>
                                        <MDBIcon color='secondary' icon='envelope' className='me-3' />
                                        info@fibametal.com
                                    </a>
                                </p>
                                <p onClick={() => window.location.href = 'tel:+48573560997'} style={{cursor: 'pointer'}}>
                                    <MDBIcon color='secondary' icon='phone' className='me-3' /> +4 857 356 0997
                                </p>
                            </MDBCol>
                        </>
                        }
                    </MDBRow>
                </MDBContainer>
            </section>

            <section className='d-flex justify-content-center justify-content-lg-between p-4 border-top' style={{backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                {showRow2 &&
                <>
                    <div className='me-5 d-none d-lg-block animate__animated animate__fadeInLeft'>
                        <span>© 2023 Copyright</span>
                    </div>

                    <div className='d-none d-lg-block fw-bold animate__animated animate__slideInUp'>
                        <span>DORUK ÇORALI</span>
                    </div>

                    <div className='ms-5 animate__animated animate__fadeInRight'>
                        <a href='https://www.linkedin.com/in/dorukcrli/' className='me-4 text-reset'>
                            <MDBIcon color='secondary' fab icon='linkedin' />
                        </a>
                        <a href='https://github.com/nidea1/' className='me-4 text-reset'>
                            <MDBIcon color='secondary' fab icon='github' />
                        </a>
                    </div>
                </>
                 }
            </section>
           
            
        </MDBFooter>
    );
}

export default Footer
