import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { getCategoryDetail, getProductDetail, getProducts } from '../api';
import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit';
import SubHeader from '../Components/SubHeader';
import { Avatar, Divider, List, ListItem, ListItemAvatar, ListItemButton, ListItemText } from '@mui/material';
import { useFooterOffset } from '../Contexts/FooterOffsetContext';

function Product() {

    const { params, productParams} = useParams();
    const navigate = useNavigate();

    const { setOffset } = useFooterOffset();

    const categoryID = params.split('-c-')[1];
    const productID = productParams.split('-p-')[1];

    const [productDetail, setProductDetail] = useState({});
    const [categoryDetail, setCategoryDetail] = useState({});
    const [categoryProducts, setCategoryProducts] = useState([]);

    useEffect(() => {

        getProductDetail(productID)
        .then((response) => {
            setProductDetail(response.data);
        })

        getCategoryDetail(categoryID)
        .then((response) => {
            setCategoryDetail(response.data);
        })

        getProducts({ kategori: categoryID })
        .then((response) => {
            setCategoryProducts(response.data);
        })

        setOffset(425);

        return () => {
            setOffset(0);
        }

    }, [categoryID, productID])

    useEffect(() => {
        document.title = `${productDetail.isim}` || 'Ürünler | Fiba Metal';
    }, [categoryDetail, productDetail])

    return (
        <>
            <SubHeader header='Ürünler' crumbs={[{ name: 'Anasayfa', path: '/' }, { name: 'Ürünler', path: '/categories' }, { name: categoryDetail.isim, path: `/category/${params}` }, { name: productDetail.isim, path: `/category/${params}/product/${productParams}`}]} />

            <section className='bg-white p-5'>
                <MDBContainer>
                    <MDBRow className='justify-content-center g-5'>
                        <MDBCol md='10' lg='8' className='mb-5'>
                            <img src={productDetail.resim} alt={productDetail.isim} className='img-fluid mb-5' width={750} height={750}/>

                            <h3 className='fw-bold'>{productDetail.isim}</h3>

                            <div className='border-bottom border-warning w-25 border-2 pb-2 mb-3' />

                            <p className='fw-bold fst-italic'>{productDetail.aciklama}</p>
                            <p className='fw-bold fst-normal'>{productDetail.fiyat} ₺/kg</p>
                            
                        </MDBCol>

                        <MDBCol md='2' lg='4' className='mb-5'>
                            <div className='border-bottom border-warning w-50 border-2 pb-2 mb-3' />

                            <h3 className='fw-bold'>Diğer Ürünler</h3>

                            <div className='border-bottom border-warning w-25 border-2 pb-2' />

                            <List sx={{bgcolor: 'backgorund.paper'}}>
                                {categoryProducts?.map((product, index) => {
                                    return (
                                        <>
                                            <ListItemButton className='rounded mb' onClick={() => {navigate(`/category/${categoryDetail.slug}-c-${categoryDetail.id}/product/${product.slug}-p-${product.id}`)}}>
                                                <ListItem alignItems='flex-start' key={index}>
                                                    <ListItemAvatar>
                                                        <Avatar alt={product.isim} src={product.resim} />
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary={<strong>{product.isim}</strong>}
                                                        secondary={product.aciklama}
                                                    />
                                                </ListItem>
                                            </ListItemButton>
                                            <Divider variant='inset' component='li' className='my-2' />
                                        </>
                                    )
                                })}
                            </List>
                            
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </section>
        </>
    )
}

export default Product
