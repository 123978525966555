import { MDBCarousel, MDBCarouselItem } from 'mdb-react-ui-kit'
import React, { useEffect, useState } from 'react'

import gorsel1 from '../assets/images/kablo.jpg'

import bakir from '../assets/images/bakir.jpg'
import aliminyum from '../assets/images/Aluminum.jpeg'


function Carousel() {

    const [is_tablet, setIsTablet] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsTablet(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        
        // Cleanup function:
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [])
    
    return (
        <div style={{ height: '100vh', overflow: 'hidden' }} className={is_tablet && 'border-bottom border-5 shadow-lg border-secondary'}>
            <MDBCarousel interval={3000}>
                <MDBCarouselItem
                    className={`${is_tablet ? 'h-50' : 'h-100 w-100'}`}
                    itemId={1}
                    src={bakir}
                    alt='...'
                >
                    <h1 className='animate__animated animate__slideInDown fw-semibold display-4 rounded-5 p-3 pt-4 user-select-none carousel-banner' style={{top: is_tablet && '-80vh'}}>BAKIR GERİ DÖNÜŞÜM</h1>
                </MDBCarouselItem>
                <MDBCarouselItem
                    className={`${is_tablet ? 'h-50' : 'h-100 w-100'}`}
                    itemId={2}
                    src={aliminyum}
                    alt='...'
                >
                    <h1 className='animate__animated animate__slideInDown fw-semibold display-4 rounded-5 p-3 pt-4 user-select-none carousel-banner' style={{top: is_tablet && '-80vh'}}>ALÜMİNYUM GERİ DÖNÜŞÜM</h1>
                </MDBCarouselItem>
                <MDBCarouselItem
                    className={`${is_tablet ? 'h-50' : 'h-100 w-100'}`}
                    itemId={3}
                    src={gorsel1}
                    alt='...'
                    style={{ right: is_tablet && '65vh', top: is_tablet && '-20vh', position: is_tablet && 'relative'}}
                >
                    <h1 className='animate__animated animate__slideInDown fw-semibold display-4 rounded-5 p-3 pt-4 user-select-none carousel-banner' style={{top: is_tablet && '-100vh'}}>HURDA BAKIR KABLO</h1>
                </MDBCarouselItem>
            </MDBCarousel>
        </div>
    )
}

export default Carousel
